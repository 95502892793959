import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Layout } from "@bw/layouts"
import {
  Partager,
  Itineraire,
  Mail,
  Facebook,
  Twitter,
  Telegram,
  Linkedin,
  WhatsApp,
} from "@bw/icons"
import { Title, Spacer, Seo, Section, CmsImage } from "@bw/bits"
import { PageHeader, Teasers } from "@bw/modules"
import { Modal, Teaser, Schedule } from "@bw/partials"
import { useTranslation } from "react-i18next"
import calculateDistance from "../utils/calculateDistance"
import { breakpoints } from "../theme"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"

const RADIUS = 20 // en kilomètres

const StationTemplate = ({ data, pageContext, location }) => {
  const [show, setShow] = React.useState(false)
  const { t } = useTranslation()
  const page = data.station
  page.services.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )
  function isDST(d) {
    let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset()
    let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset()
    return Math.max(jan, jul) !== d.getTimezoneOffset()
  }
  const schedule = isDST(new Date())
    ? page.schedule.summer
    : page.schedule.winter

  const stations = data.allStation.nodes
    .map(point => ({
      ...point,
      distance: calculateDistance(page.lat, page.lng, point.lat, point.lng),
    }))
    .filter(point => point.distance <= RADIUS)
    .sort((a, b) => a.distance - b.distance)

  if (typeof page === "undefined") {
    return null
  }

  return (
    <Layout {...{ pageContext }}>
      <Seo title={page.name} description={page.name} />
      <Section>
        <PageHeader
          title={page.name}
          subtitle={`${page.zip} ${page.city.name}`}
        />
      </Section>

      <Section>
        <Grid>
          <div>
            <p>
              {page.street}
              <br />
              {page.zip} {page.city.name}, {page.county}
            </p>
            {page.phone_1 && (
              <p>
                <a href={`tel:${page.phone_1.replace(/ /g, "")}`}>
                  {page.phone_1}
                </a>
              </p>
            )}
            {page.phone_2 && (
              <p>
                <a href={`tel:${page.phone_2.replace(/ /g, "")}`}>
                  {page.phone_2}
                </a>
              </p>
            )}
            <Spacer direction="row">
              <a
                href={
                  "https://www.google.ch/maps/dir//" +
                  encodeURIComponent(
                    [page.street, page.zip, page.city.name, "CH"].join(" ")
                  )
                }
                target="_blank"
                rel="noreferrer"
                className="phantom-btn"
              >
                <Itineraire />
                <span>{t("itinerary")}</span>
              </a>
              <button
                className="phantom-btn"
                onClick={() => {
                  setShow(true)
                }}
              >
                <Partager />
                <span>{t("share")}</span>
              </button>
              <Modal {...{ show, setShow }}>
                <Title title={t("share")} />
                <Spacer direction="row">
                  <EmailShareButton
                    url={location.href}
                    resetButtonStyle={false}
                  >
                    <Mail />
                  </EmailShareButton>
                  <FacebookShareButton
                    url={location.href}
                    resetButtonStyle={false}
                  >
                    <Facebook />
                  </FacebookShareButton>
                  <LinkedinShareButton
                    url={location.href}
                    resetButtonStyle={false}
                  >
                    <Linkedin />
                  </LinkedinShareButton>
                  <TelegramShareButton
                    url={location.href}
                    resetButtonStyle={false}
                  >
                    <Telegram />
                  </TelegramShareButton>
                  <TwitterShareButton
                    url={location.href}
                    resetButtonStyle={false}
                  >
                    <Twitter />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={location.href}
                    resetButtonStyle={false}
                  >
                    <WhatsApp />
                  </WhatsappShareButton>
                </Spacer>
              </Modal>
            </Spacer>
            <br />
            <Schedule schedule={schedule} offset />
            <h4 style={{ marginTop: "45px" }}>{t("storeLocator.services")}</h4>
            <ServiceGrid>
              {page.services.map((service, i) => (
                <Icon key={i}>
                  <img src={service.icon_select} alt={service.slug} />
                  <span>{service.name}</span>
                </Icon>
              ))}
            </ServiceGrid>
          </div>
          <div>
            <CmsImage src={page.image} alt={page.name} />
          </div>
        </Grid>
      </Section>

      {stations.length > 0 && (
        <Section title={t("station.nearBy")}>
          <Teasers>
            {stations.map((nearbyStation, i) => (
              <Teaser
                key={i}
                title={nearbyStation.name}
                suptitle={`${nearbyStation.zip} ${
                  nearbyStation.city.name
                } (${nearbyStation.distance.toFixed(1).replace(".0", "")}km)`}
                to={nearbyStation.slug}
              />
            ))}
          </Teasers>
        </Section>
      )}
    </Layout>
  )
}

export function Head({ data }) {
  const station = data.station

  function isDST(d) {
    let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset()
    let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset()
    return Math.max(jan, jul) !== d.getTimezoneOffset()
  }

  const schedule = isDST(new Date())
    ? station.schedule.summer
    : station.schedule.winter

  const openingHours = []

  schedule.forEach(day => {
    let dayName = day.day

    if (dayName == "weekdays") {
      const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]

      for (let i = 0; i < 5; i++) {
        const dayObject = {}
        dayObject["@type"] = "OpeningHoursSpecification"

        if (day.hours.length > 1) {
          dayObject.closes = `${day.hours[1].to}:00`
        } else {
          dayObject.closes = `${day.hours[0].to}:00`
        }
        dayObject.dayOfWeek = `https://schema.org/${dayNames[i]}`
        dayObject.opens = `${day.hours[0].from}:00`

        openingHours.push(dayObject)
      }
    } else {
      const dayObject = {}
      dayObject["@type"] = "OpeningHoursSpecification"
      dayObject.closes = `${day.hours[0].to}:00`
      dayObject.dayOfWeek = `https://schema.org/${
        String(dayName[0]).toUpperCase() + String(dayName).slice(1)
      }`
      dayObject.opens = `${day.hours[0].from}:00`

      openingHours.push(dayObject)
    }
  })

  return (
    <>
      <script type="application/ld+json">{`
      {
        "@type": "LocalBusiness",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "${station.city.name}",
          "addressRegion": "${station.county}",
          "postalCode": "${station.zip}",
          "streetAddress": "${station.street}"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "${station.lat}",
          "longitude": "${station.lng}"
        },
        "name": "${station.name}",
        "telephone": "${station.phone_1}",
        "openingHoursSpecification": ${JSON.stringify(openingHours)}
      }
      `}</script>
    </>
  )
}

export default StationTemplate

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;

  @media (min-width: ${breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${breakpoints.large}px) {
    grid-template-columns: 450px 1fr;
  }
`

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-gap: 10px;
`

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 60px;
  width: 80px;
  font-size: 10px;
  line-height: 1;
  background-color: #f0f0f0;
  border-radius: 8px;

  img {
    height: 24px;
    width: auto;
    margin-bottom: 5px;
  }
`

export const stationQuery = graphql`
  query stationQuery($id: String!, $locale: String!) {
    station(id: { eq: $id }) {
      lat
      lng
      name
      phone_1
      phone_2
      street
      zip
      image {
        childImageSharp {
          gatsbyImageData(width: 650)
        }
      }
      city {
        name
      }
      county
      services {
        name
        icon_select
      }
      schedule {
        summer {
          hours {
            from
            to
          }
          day
        }
        winter {
          day
          hours {
            from
            to
          }
        }
      }
    }
    allStation(filter: { locale: { eq: $locale }, id: { ne: $id } }) {
      nodes {
        lat
        lng
        slug
        name
        zip
        city {
          name
        }
        county
        schedule {
          summer {
            hours {
              from
              to
            }
            day
          }
          winter {
            day
            hours {
              from
              to
            }
          }
        }
      }
    }
  }
`
